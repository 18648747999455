import React from 'react';
import cn from 'classnames';
import BetButton from '../../components/BetButton';
import AppStoreButtons from '../../components/AppStoreButtons';
import Image from '../../components/Image';
import Text from '../../components/Text';
import Heading from '../../components/Heading';
import { TeaserPropTypes } from '../../Teaser';
import styles from '../../styles.module.scss';

const TeaserES = ({ segment, index, textBeforeImage }) => {
  const { imgUrl, imgWebpUrl, imgPositionY, imgPositionX, text, appStoreBtn, btn } = segment;
  return (
    <article className={styles.teaser} data-testid="teaser_element">
      <div
        className={cn(styles.container, {
          [styles.reverse]: imgPositionX ? imgPositionX === 'right' : !textBeforeImage,
        })}
      >
        <Image position={imgPositionY} webpUrl={imgWebpUrl} url={imgUrl} />
        <div className={styles.content}>
          <Text>
            {RowWrapper => (
              <>
                {text.map(({ header, paragraph }, idx) => (
                  <RowWrapper key={header.split(' ').join('')}>
                    <Heading level={idx === 0 && index === 0 ? 1 : 2}>{header}</Heading>
                    <div dangerouslySetInnerHTML={{ __html: paragraph }} />
                  </RowWrapper>
                ))}
              </>
            )}
          </Text>
          {appStoreBtn?.iosHref || appStoreBtn?.androidHref ? (
            <AppStoreButtons iosHref={appStoreBtn.iosHref} androidHref={appStoreBtn.androidHref} />
          ) : null}
          {!!btn && <BetButton href={btn.betHref} text={btn.betText} />}
        </div>
      </div>
    </article>
  );
};

TeaserES.propTypes = {
  ...TeaserPropTypes,
};

export default TeaserES;
